<template>
  <button class="action-btn">
    <slot />
    <p class="action-btn__title" v-if="$slots.title">
      <slot name="title" />
    </p>
  </button>
</template>

<style lang="scss" scoped>
.action-btn {
  background-color: var(--background-color-elevated-1);
  border: none;
  border-radius: 0;
  cursor: pointer;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer;
  transition: background 80ms ease;

  &:hover {
    background-color: var(--background-color-elevated-2);
  }

  &__title {
    font-size: 0.75rem;
    font-family: 'Rubik', sans-serif;
    color: var(--text-color);
    text-align: center;
    margin-top: 0.25rem;
  }
}
</style>
