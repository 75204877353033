
import { defineComponent } from 'vue';
import ActionButton from '@/components/ui/ActionButton.vue';
import Quiz from '@/components/quiz/Quiz.vue';
import { mapActions, mapState } from 'pinia';
import { useStore } from '@/store';
import { ServerState } from '@shared/types';

export default defineComponent({
  name: 'QuizDetail',
  components: { ActionButton, Quiz },
  computed: {
    ...mapState(useStore, ['serverState']),
    ...mapState(useStore, {
      currentQuiz(store): ServerState['quizes'][string] | undefined {
        if (!store.serverState) return;

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const quizId: string = (this as any).currentQuizId;

        return store.serverState.quizes[quizId];
      },
      quizNumber(store): number {
        if (!store.serverState) return 0;

        return (
          Object.values(store.serverState.quizes).filter(
            (quiz) => quiz.played || quiz.active
          ).length +
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ((this as any).currentQuiz.active ? 0 : 1)
        );
      },
      quizTotal(store): number {
        if (!store.serverState) return 0;

        return Object.values(store.serverState.quizes).filter(
          (quiz) => !quiz.disabled
        ).length;
      },
      guesses(store) {
        if (!store.serverState) return;

        return Object.fromEntries(
          Object.entries(store.serverState.players)
            .map(([playerId, player]) => {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              const that = this as any;
              const guess: number | undefined =
                that.currentQuiz.guesses[playerId]?.guess;
              const guessing: boolean =
                that.currentQuiz.guesses[playerId]?.guessing || false;
              const correctAnswer = that.currentQuiz.quiz.answer.value;
              const absoluteDifference =
                guess !== undefined
                  ? Math.round(guess - correctAnswer)
                  : undefined;
              const relativeDifference =
                guess !== undefined
                  ? Math.round(
                      ((guess - correctAnswer) / correctAnswer) * 1000
                    ) / 10
                  : undefined;

              return [
                playerId,
                {
                  ...player,
                  guess,
                  guessing,
                  absoluteDifference,
                  relativeDifference,
                },
              ] as const;
            })
            .sort(
              ([, a], [, b]) =>
                Math.abs(a?.absoluteDifference ?? Infinity) -
                Math.abs(b?.absoluteDifference ?? Infinity)
            )
        );
      },
    }),
    currentQuizId(): string {
      let quizId = this.$route.params.id;

      if (Array.isArray(quizId)) {
        quizId = quizId[0];
      }

      return quizId;
    },
  },
  methods: {
    ...mapActions(useStore, [
      'startQuiz',
      'changeQuizState',
      'setQuizDisabled',
      'resetQuiz',
      'setQuizPlayed',
      'setQuizGuessing',
      'resetQuizGuesses',
    ]),
    startCurrentQuiz() {
      this.startQuiz(this.currentQuizId);
    },
    nextState() {
      this.changeQuizState(this.currentQuizId, 1);
    },
    previousState() {
      this.changeQuizState(this.currentQuizId, -1);
    },
    toggleDisabled() {
      this.setQuizDisabled(
        this.currentQuizId,
        !this.currentQuiz?.disabled ?? true
      );
    },
    resetCurrentQuiz() {
      this.resetQuiz(this.currentQuizId);
    },
    togglePlayed() {
      this.setQuizPlayed(this.currentQuizId, !this.currentQuiz?.played);
    },
    toggleGuessing() {
      this.setQuizGuessing(this.currentQuizId, !this.currentQuiz?.guessing);
    },
    resetGuesses() {
      this.resetQuizGuesses(this.currentQuizId);
    },
  },
});
